<template>
  <div class="container flex flex-row">
    <div class="relative w-12/12 md:inline-block md:w-3/12">
      <span
        v-if="props.modules?.content"
        class="w-12/12 text-center md:w-9/12 md:text-left"
      >
        <RichTextRenderer
          :document="props.modules.content"
          :node-renderers="nodeRenderers"
        />
      </span>
      <select
        v-if="props.modules?.tabs"
        v-model="selectedOuterTab"
        class="btn bold relative block h-[57px] w-full border-1 border-blue bg-white px-1 py-[17px] text-left uppercase text-blue-dark md:hidden md:w-[241px]"
      >
        <option
          v-for="(tab, index) in props.modules.tabs"
          :key="index"
          :value="tab.fields?.label || ''"
        >
          {{ tab.fields?.label }}
        </option>
      </select>
      <div v-if="props.modules?.tabs" class="hidden md:block">
        <span
          v-for="(tab, index) in props.modules.tabs"
          :key="index"
          :class="{ 'border-transparent': index === 0 }"
          class="block border-t-1 border-gray-dark border-opacity-25"
        >
          <h5
            class="relative m-0 flex-row items-center justify-between py-1 transition-all ease-out hover:cursor-pointer md:flex"
            :class="
              outerTabActiveIndex === index
                ? 'text-blue hover:cursor-default'
                : 'text-blue-dark hover:cursor-pointer hover:opacity-75'
            "
            @click="outerTabActiveIndex = index"
          >
            {{ tab.fields?.label || '' }}
            <IconsSvgPlusIcon :active="outerTabActiveIndex === index" />
          </h5>
          <Transition name="fade">
            <ul v-show="outerTabActiveIndex === index" class="mt-1/2">
              <li
                v-for="(innerTab, indexB) in tab.fields.tabs"
                :key="indexB"
                class="relative block transition-all ease-out"
              >
                <p
                  class="relative mb-1 inline-block"
                  role="button"
                  :class="
                    selectedInnerTab === innerTab.fields?.id
                      ? 'font-bold text-blue-dark hover:cursor-default'
                      : 'text-gray hover:cursor-pointer hover:text-blue-dark'
                  "
                  @click="selectedInnerTab = innerTab.fields?.id"
                >
                  {{ innerTab.fields?.label || '' }}
                </p>
              </li>
            </ul>
          </Transition>
        </span>
      </div>
      <ul
        v-if="props.modules?.tabs"
        class="list-style-none relative w-full md:hidden"
      >
        <li
          v-for="(tab, indexA) in props.modules.tabs"
          :key="indexA"
          class="block"
        >
          <ul v-if="tab?.fields?.tabs">
            <template v-if="tab.fields?.label === selectedOuterTab">
              <li
                v-for="(innerTab, indexB) in tab.fields.tabs"
                :key="indexB"
                class="relative block border-t-1 border-gray-dark border-opacity-25 py-1 transition-all ease-out first-of-type:border-transparent"
              >
                <h5
                  class="relative m-0 flex flex-row items-center justify-between"
                  :class="
                    innerTabActiveIndex === indexB
                      ? 'text-blue'
                      : 'text-blue-dark'
                  "
                  @click="innerTabActiveIndex = indexB"
                >
                  {{ innerTab.fields?.label || '' }}
                  <IconsSvgPlusIcon :active="innerTabActiveIndex === indexB" />
                </h5>
                <Transition name="fade">
                  <span
                    v-show="indexB === innerTabActiveIndex"
                    class="aspect--16x9 relative mb-1 mt-1 block h-0 bg-gray-lightest md:hidden"
                  >
                    <ImagesPicture
                      v-if="
                        innerTab.fields?.image?.sys?.contentType?.sys?.id ===
                        'image'
                      "
                      :image="innerTab?.fields?.image?.fields"
                      :classes="'w-full absolute top-0 right-0 bottom-0 left-0 object-cover h-full z-1 mb-1'"
                      :lazy="false"
                    />
                  </span>
                </Transition>
                <Transition name="fade">
                  <span
                    v-if="innerTab.fields.tabContent"
                    v-show="indexB === innerTabActiveIndex"
                  >
                    <RichTextRenderer
                      :document="innerTab.fields.tabContent"
                      :node-renderers="nodeRenderers"
                    />
                  </span>
                  <span
                    v-else-if="innerTab.fields?.featureTabContent"
                    v-show="indexB === innerTabActiveIndex"
                  >
                    <RichTextRenderer
                      :document="innerTab.fields.featureTabContent"
                      :node-renderers="nodeRenderers"
                    />
                  </span>
                </Transition>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>
    <div
      v-if="props.modules?.tabs"
      class="relative hidden md:ml-1/12 md:inline-block md:w-8/12"
    >
      <Transition name="fade">
        <span
          v-if="innerTabContent.image?.sys?.contentType?.sys?.id === 'video'"
          class="aspect--16x9 relative mb-1 block h-0 bg-gray-lightest"
        >
          <VideosTheVideo
            class="w-full"
            :key="innerTabContent.image?.fields?.name"
            :mp4-player-state="'play'"
            :video="activeVideo"
          />
        </span>
      </Transition>
      <Transition>
        <span
          v-if="innerTabContent.image?.sys?.contentType?.sys?.id === 'image'"
          class="aspect--16x9 relative mb-1 block h-0 bg-gray-lightest"
        >
          <ImagesPicture
            :key="innerTabContent.image?.fields?.alt"
            :image="innerTabContent.image?.fields"
            :classes="'w-full absolute top-0 right-0 bottom-0 left-0 object-cover h-full z-1 mb-1'"
            :lazy="false"
          />
        </span>
      </Transition>
      <span v-if="innerTabContent.tabContent" class="relative">
        <RichTextRenderer
          :document="innerTabContent.tabContent"
          :node-renderers="nodeRenderers"
        />
      </span>
      <span v-else-if="innerTabContent.featureTabContent" class="relative">
        <RichTextRenderer
          :document="innerTabContent.featureTabContent"
          :node-renderers="nodeRenderers"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  const props = defineProps({
    modules: {
      type: Object,
      required: true,
    },
  });

  const innerTabActiveIndex = ref(0);
  const outerTabActiveIndex = ref(0);
  const selectedInnerTab = ref('');
  const selectedOuterTab = ref('');

  const innerTabContent = reactive({});
  const activeVideo = computed(() => innerTabContent.image?.fields);

  watch(selectedInnerTab, (newSelectedInnerTab) => {
    if (!props.modules.tabs) return;

    props.modules.tabs.forEach((tab) => {
      if (!tab.fields.tabs) return;

      tab.fields.tabs.forEach((innerTab) => {
        if (
          innerTab.fields.label &&
          innerTab.fields.id === newSelectedInnerTab
        ) {
          Object.assign(innerTabContent, { ...innerTab.fields });
        }
      });
    });
  });

  const findFirstExistingTabId = () => {
    if (!props.modules?.tabs[0].fields?.tabs) {
      return '';
    }

    for (const tab of props.modules?.tabs[0].fields?.tabs) {
      if (tab.fields.id) {
        return tab.fields.id;
      }
    }
  };

  onMounted(() => {
    selectedOuterTab.value = props.modules?.tabs[0].fields?.label || '';
    selectedInnerTab.value = findFirstExistingTabId();
  });
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s linear;
    overflow: hidden;
  }
  .fade-leave-to,
  .fade-enter,
  .fade-leave-active {
    transition: all 0.2s linear;
    max-height: 0px !important;
    opacity: 0;
  }
  select {
    background: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' viewBox='0 0 20 10' fill='none' %3e%3cpath d='M20 0L0 0L10.2703 10L20 0Z' fill='%230384FB' /%3e%3c/svg%3e")
      white no-repeat calc(100% - 10px) !important;
    background-position: calc(100% - 0.75rem) center;
    appearance: none;
    @apply p-0 pl-[20px];
    &:active,
    &:focus {
      @apply border-blue;
    }
  }
</style>
